@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.slider-list {
	color: colors.$grey-400;

	&:hover {
		.slider-list__btn {
			opacity: 1;
		}
	}
}

.slider-list__wrapper {
	padding: 0 2rem;
}

.swiper-wrapper {
	position: relative;
}

.slider-list__slide {
	border-top: 2px solid colors.$grey-200;
	border-bottom: 2px solid colors.$grey-200;
	transition: all 400ms ease-in-out;

	&:hover {
		border-color: colors.$blue;
	}
}

.slider-list__btn {
	position: absolute;
	transform: translate(-50%, -50%);
	left: calc(100% - 7%);
	z-index: 100;
	border: 2px solid colors.$blue;
	border-radius: 50%;
	color: colors.$blue;
	opacity: 0;
	transition: all 400ms ease-in-out;
	width: 7rem;
	height: 7rem;

	i {
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		position: absolute;
	}

	&:hover {
		cursor: pointer;
		opacity: 1;
		background-color: colors.$blue;
		color: colors.$white;
	}

	i {
		font-size: 2.5rem;
	}

	&.swiper-button-disabled {
		display: none;
	}

	&--prev {
		top: 40%;
	}

	&--next {
		top: 60%;
	}
}

.slider-list-item {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 4rem;
	padding: 3.7rem 10rem 3.7rem 2.9rem;
	transition: all 400ms ease-in-out;
	text-decoration: none;
	color: colors.$black;

	&:visited {
		color: colors.$black;
	}

	&:not(:last-of-type) {
		border-bottom: 2px solid colors.$grey-200;
	}

	&:hover {
		cursor: pointer;
		background-color: colors.$grey-100;
		border-color: colors.$blue;

		.slider-list-item__btn.btn {
			border-color: colors.$blue;
			background-color: colors.$blue;

			i {
				color: colors.$white;
			}
		}
	}

	.slider-list-item__btn.btn {
		position: unset !important;
		transition: all 400ms ease-in-out;
		padding: 2rem;

		i {
			font-size: 2rem;
		}

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}
}

.slider-list-item__thumbnail {
	border-radius: 10px;
	height: fit-content;
}

.slider-list-item__content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 2rem;
}

.slider-list-item__infos {
	display: grid;
	grid-template-columns: minmax(1rem, 1fr);
	row-gap: 1.5rem;

	.title {
		@extend %font-md;
		font-weight: 700;
		margin-bottom: 0;
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.slider-list__wrapper {
		padding: 0;
	}

	.slider-list__btn {
		opacity: 0;
	}

	.slider-list-item {
		flex-direction: row;
		padding: 3.7rem 2.9rem;
	}

	.slider-list-item__content {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
}
